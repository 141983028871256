const router_modular = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/HomeView')
	},
	{
		path: '/menu',
		name: 'menu',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '@/views/MenuView.vue')
	},
	{
		path: '/appointmentClient',
		name: 'appointmentClient',
		component: () => import('@/views/appointmentClient/appointmentClient.vue')
	},
	{
		path: '/getInfo',
		name: 'getInfo',
		component: () => import('@/views/getInfo/getInfo.vue')
	},
	{
		path: '/myUser',
		name: 'myUser',
		component: () => import('@/views/myUser/myUser.vue')
	},
	{
		path: '/ordersUser',
		name: 'ordersUser',
		component: () => import('@/views/ordersUser/ordersUser.vue')
	},
	{
		path: '/personalInfo',
		name: 'personalInfo',
		component: () => import('@/views/personalInfo/personalInfo.vue')
	}
];
export default router_modular;
