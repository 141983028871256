import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'amfe-flexible';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios';
Vue.use(Vant);
Vue.config.productionTip = false;
axios.defaults.baseURL = '/api';

// 请求拦截器
axios.interceptors.request.use(
	(config) => {
		// if (token) config.headers.authorization = token;
		config.headers['token'] = localStorage.getItem('token') || '';
		return config;
	},
	(error) => Promise.reject(error)
);
// 添加响应拦截器
axios.interceptors.response.use(
	function (response) {
		if (response.data.code == 20016) {
			localStorage.removeItem('token');
		}
		// 对响应数据做点什么
		return response;
	},
	(error) => Promise.reject(error)
);
Vue.prototype.$http = axios;
Vue.prototype.$baseurl = '/api';
Vue.prototype.$url = 'https://resource.gomicsgene2.com:8086';

import moment from 'moment';
Vue.filter('datefmt', function (input, fmtstring) {
	//当input为时间戳时，需转为Number类型
	// 使用momentjs这个日期格式化类库实现日期的格式化功能
	return moment(input).format(fmtstring);
});

new Vue({
	axios,
	router,
	render: (h) => h(App)
}).$mount('#app');
