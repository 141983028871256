import Vue from 'vue';
import VueRouter from 'vue-router';
import router_modular from '@/router/router_modular/index.js';
Vue.use(VueRouter);

const routes = [...router_modular];

const router = new VueRouter({
	routes
});
//全局路由守卫
router.beforeEach((to, from, next) => {
	const token = window.localStorage.getItem('token');
	if (to.path === '/') {
		if (token != null) {
			return next('/menu');
		}
		return next();
	} // 访问登录页，直接放行
	// 获取token

	// 如果token过期了
	if (!token) {
		if (to.path == '/') return next();
		// 注意要import element的Message组件
		alert('登录状态过期，请重新登录');
		return next('/');
		// 如果token没有过期，又是选择了登录页面就直接重定向到首页，不需要重新输入账户密码
	} else if (to.path == '/') {
		return next('');
	}
	next();
});

export default router;
